import defaultTheme from '@guestyci/foundation/theme/defaultTheme';
import Translations from '../translations';

type TTheme = typeof defaultTheme;

export default TTheme;

export interface IAppData {
  accountId?: string;
  config?: {};
  device?: string;
  elementId: string;
  inEditor?: boolean;
  locale: string;
  page?: string;
  refresh?: boolean | undefined;
  siteId: string;
  widgetId: string;
  widgetVersion?: string;
}

export interface IPicture {
  _id: string;
  original: string;
  thumbnail: string;
  height: number;
  width: number;
  size: number;
}

export interface IAddress {
  city: string;
  country?: string | null;
  full: string;
  lat: number;
  lng: number;
  state: string;
  street: string;
  zipcode: string;
}

export interface IPrice {
  monthlyPriceFactor: number;
  weeklyPriceFactor: number;
  currency: string;
  basePrice: number;
  securityDepositFee?: string | null;
}

export interface IReview {
  avg: number;
  total: number;
}

export interface IListing {
  _id: string;
  title: string;
  publicDescription: { summary: string };
  pictures: IPicture[];
  propertyType: string;
  url: string;
  prices: IPrice;
  address: IAddress;
  reviews: IReview;
  amenities?: string[];
}

export interface MultiSelectProps {
  target: {
    value: string[];
    name: string;
  };
}
export interface SingleSelectProps {
  target: {
    value: string;
    name: string;
  };
}

export interface IFilterSettings {
  amenities?: string[]; // multi select
  propertyType?: string; // select
  propertyTypes: string[]; // select
  tags: Array<{ id: string; label: string }>;
  numOfBedrooms?: number;
  numOfBathrooms?: number;
  priceRange: [number | null, number | null];
  isShowSortBy: boolean;
  isShowFilters: boolean;
  isShowPriceRange: boolean;
  url: string;
}


export interface ISearchSettings {
  location?: { city: string; country: string; state?: string };
  amenities?: string[];
  propertyType: string;
  tags: Array<{ key: string; label: string }>;
  numOfBedrooms?: number;
  numOfBathrooms?: number;
  priceRange: [number | null, number | null];
  sortBy: string;
}

export interface IWidgetSettings {
  // booking engine page url to redirect
  url: string;
  currency: string;
  // possible options for form
  options: {
    // use existing method to tage uniq cities form ROV
    locations: Array<{ city: string; country: string }>;

    // from engine config in db, remap constants to values
    amenities: string[];

    // from engine config in db
    propertyTypes: string[];

    // from engine config in db - empty for old engine without this data
    tags: Array<{ id: string; label: string }>;

    // from engine config in db
    maxNumOfBedrooms?: number;

    // from engine config in db
    maxNumOfBathrooms?: number;

    sortBy: string[];

    sortSettings: string[];

    categoriesFilterName?: string;

    // NOTE: not field for price range
  };
  // saved settings - section for search results
  searchSettings: ISearchSettings;

  // saved settings - section for filter default values
  // filter will use engine settings to how avaialbe values

  filterSettings: IFilterSettings;
}

export interface IOption {
  value: string;
  label: string;
}

export interface IOptionTag {
  id: string;
  label: string;
}

export const SORT_BY = {
  AREA_SQUARE_FEET: 'areaSquareFeet',
  BASE_PRICE: 'prices.basePrice',
  NUMBER_OF_GUESTS: 'numberOfGuests',
  NUMBER_OF_BEDROOMS: 'numberOfBedrooms',
};

export const SORT_ORDER = {
  ASC: 'asc',
  DESC: 'desc',
};

export interface ISortOption {
  [key: string]: {
    label: string;
    sortBy: string;
    sortOrder: string;
  };
}

export const getSortOptions = (locale: string = 'en'): ISortOption => {
  const t = Translations.getInstance(locale).getTranslations.bind(
    Translations.getInstance(locale)
  );
  return {
    AREA_SQUARE_FEET_DESC: {
      label: t('Floor surface/Property size - high to low'),
      sortBy: SORT_BY.AREA_SQUARE_FEET,
      sortOrder: SORT_ORDER.DESC,
    },
    BASE_PRICE_DESC: {
      label: t('Price - high to low'),
      sortBy: SORT_BY.BASE_PRICE,
      sortOrder: SORT_ORDER.DESC,
    },
    BASE_PRICE_ASC: {
      label: t('Price - low to high'),
      sortBy: SORT_BY.BASE_PRICE,
      sortOrder: SORT_ORDER.ASC,
    },
    NUMBER_OF_GUESTS_DESC: {
      label: t('Maximum occupancy - high to low'),
      sortBy: SORT_BY.NUMBER_OF_GUESTS,
      sortOrder: SORT_ORDER.DESC,
    },
    NUMBER_OF_BEDROOMS_DESC: {
      label: t('Count of bedrooms - high to low'),
      sortBy: SORT_BY.NUMBER_OF_BEDROOMS,
      sortOrder: SORT_ORDER.DESC,
    },
  };
};
