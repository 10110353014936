import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';

import createStyles from '@guestyci/foundation/createStyles';
import { Col, Row } from '@guestyci/foundation/Layout';
import Spinner from '@guestyci/foundation/Spinner';
import Button from '@guestyci/foundation/Button';
import TextField from '@guestyci/foundation/TextField';

import TTheme from '../common/types';
import { Listings } from '../components/Listings';
import { LayoutSwitcher } from '../components/LayoutSwitcher';
import { SearchByFilter } from '../components/Filters/SearchByFilter';
import { TopFiltersGroup } from '../components/Filters/TopFiltersGroup';
import { getRedirectUrl } from '../common/utils';
import { WebsiteSettingsContext } from '../context/SettingsProvider';
import Translations from '../translations';

const useStyles = createStyles((theme: TTheme) => ({
  "@global": {
    body: {
      background: "transparent",
      fontFamily: theme?.typography?.base.fontFamily,
      lineHeight: theme?.typography?.base.lineHeight,
    },
    button: {
      background: 'transparent',
    },
    '& [class*="Dropdown"] button': {
      background: 'transparent',
      fontSize: 14,

      '&:hover': {
        background: 'transparent',
      }
    },
    '& input[class*=Input-input]': {
      fontSize: 14,
      '&::placeholder': {
        fontSize: 12,
      }
    },
    '& [class*=Menu-root] [class*=Dropdown-menuList] [class*=MenuItem]': {
      fontSize: 14,
    }
  },
  root: {
    height: '100%',
    width: '100%',
    maxWidth: 1024,
    margin: '30px auto',

    '&.gridView': {
      display: 'flex',
    },

    '& *': {
      fontFamily: theme.typography.base.fontFamily,
    },
  },
  spinner: {
    width: '100vh',
    height: '100vh',
    position: 'fixed',
  },
  filters: {
    '@media (min-width: 0) and (max-width: 695px)': {
      display: 'none',
    },
    '@media (min-width: 696px) and (max-width: 1007px)': {
      width: 665,
      margin: '0 auto',
    },
  },
  searchFilters: {
    marginTop: 8,
    marginBottom: 10,
  },
}));

const LandingPage = () => {
  const classes = useStyles();
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);
  const [redirectUrl, setRedirectUrl] = useState<string>('');
  const { widgetSettings, appData } = useContext(WebsiteSettingsContext);
  const { filterSettings, options } = widgetSettings;
  const [isLayoutToggled, setIsLayoutToggled] = useState<boolean>(false);
  const t = Translations.getInstance(appData?.locale).getTranslations.bind(
    Translations.getInstance(appData?.locale)
  );
  const toggleGrid = () => {
    setIsLayoutToggled(true);
  };
  const toggleList = () => {
    setIsLayoutToggled(false);
  };
  const handleFormChange = () => {
    const href = getRedirectUrl({ widgetSettings, locale: appData.locale });
    setRedirectUrl(href);
  };

  const handleClickListing = (id: string = '') => {
    setShouldRedirect(true);
    const href = getRedirectUrl({ widgetSettings, locale: appData.locale, id });
    setRedirectUrl(href);
  };

  const handleFormClose = useCallback(() => {
    setShouldRedirect(true);
  }, []);

  const handleLoadMore = () => {
    handleFormChange();
    setShouldRedirect(true);
  };

  useEffect(() => {
    if (redirectUrl && shouldRedirect) {
      if (appData.inEditor) {
        // @ts-ignore
        if (window?.top?.location?.hash?.indexOf('preview') > 0) window.open(redirectUrl, '_blank');
        setShouldRedirect(false);
      } else {
        window.location.href = redirectUrl;
      }
    } else {
      setShouldRedirect(false);
    }
  }, [redirectUrl, shouldRedirect, appData.inEditor]);

  return (
    <div className={cn(classes.root, { gridView: isLayoutToggled})}>
      {shouldRedirect && <Row justify="center" align="center" fullWidth className={classes.spinner}>
        <Spinner />
      </Row>}
      <Col fullWidth>
        <TopFiltersGroup className={classes.filters} onClose={handleFormClose} onChange={handleFormChange} />
        <Row className={cn(classes.filters, classes.searchFilters)} justify={!filterSettings?.isShowSortBy ? 'end' : 'between'}>
          {filterSettings?.isShowSortBy &&
            <SearchByFilter
              onClose={handleFormClose}
              onSelect={handleFormChange}
              locale={appData.locale}
              options={options.sortBy}
            />}
          <LayoutSwitcher toggleList={toggleList} toggleGrid={toggleGrid} toggled={isLayoutToggled} />
        </Row>
        <Row justify="center" fullWidth>
          <Listings onClick={handleClickListing} isGridView={isLayoutToggled} />
        </Row>
        <Row justify="center">
          <Button onClick={handleLoadMore}>
            <TextField bold variant="h3">{t('Load more')}</TextField>
          </Button>
        </Row>
      </Col>
    </div>
  );
};

export default LandingPage;
