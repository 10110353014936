import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';

import CurrencySymbols from '@guestyci/foundation/constants/currencySymbols';
import { Row } from '@guestyci/foundation/Layout';
import Dropdown, { Option } from '@guestyci/foundation/Dropdown';
import createStyles from '@guestyci/foundation/createStyles';
import Input from '@guestyci/foundation/Input';
import DropdownLabelInput from '@guestyci/foundation/Dropdown/DropdownLabelInput';

import TTheme, {
  IOption,
  IOptionTag,
  MultiSelectProps,
  SingleSelectProps,
} from '../../../common/types';
import { formatOptions } from '../../../common/utils';
import { DropdownMenu } from '../../DropDownMenu';
import { WebsiteSettingsContext } from '../../../context/SettingsProvider';
import Translations from '../../../translations';
import { DIO_EVENTS } from '../../../common/constants';
import useDio from '../../../hooks/useDio';

export interface IFormPropsValues {
  includeAmenities?: [],
  tags?: Array<IOptionTag>,
  propertyType?: IOption | null,
  numberOfBedrooms?: number | string | null,
  numberOfBathrooms?: number | string | null,
  pricePerNight?: number | null,
  minPrice?: number,
  maxPrice?: number,
  sortBy?: string | null,
  sortOrder?: string | null,
}

const formPropsInitialValues: IFormPropsValues = {
  includeAmenities: [],
  tags: [],
  propertyType: null,
  numberOfBedrooms: null,
  numberOfBathrooms: null,
  pricePerNight: null,
  minPrice: undefined,
  maxPrice: undefined,
  sortBy: null,
  sortOrder: null,
};

const DIO_FILTER_EVENTS_MAP: { [key: string]: string } = {
  sortBy: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_SORT_BY_OPTION,
  tags: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_CATEGORIES_FILTER_OPTION,
  minPrice: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_PRICE_PER_NIGHT_FILTER_OPTION,
  maxPrice: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_PRICE_PER_NIGHT_FILTER_OPTION,
  propertyType: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_PROPERTY_TYPE_FILTER_OPTION,
  includeAmenities: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_AMENITIES_FILTER_OPTION,
  numberOfBedrooms: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_NUMBER_OF_BED_FILTER_OPTION,
  numberOfBathrooms: DIO_EVENTS.CLICK_SEARCH_RESULT_PAGE_NUMBER_OF_BATH_FILTER_OPTION,
}

export type TFormProps = typeof formPropsInitialValues;

const useStyles = createStyles((theme: TTheme) => ({
  root: {
    marginBottom: 12,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  priceWrapper: {
    display: 'flex',
    cursor: 'pointer',
  },
  priceInput: {
    display: 'flex',
    padding: theme.spacer(2),
    '& input': {
      fontSize: theme.typography.h5,
      color: theme.palette.text.secondary,
      '::placeholder': {
        fontSize: theme.typography.h5,
        color: theme.palette.text.secondary,
      },
    },
  },
  priceRange: {
    minWidth: 180,
  },
  dropDown: {
    width: 'auto',
    lineHeight: `${theme.spacer(6)}px`,
    fontSize: theme.typography.fontSize.h5,
  },
  dropDownInputLabel: {
    fontSize: theme.typography.fontSize.h5,
    border: `1px solid ${theme.palette.uiBorder}`,
    padding: theme.spacer(2),
    borderRadius: theme.spacer(1),
    cursor: 'pointer',
    color: theme.palette.text.default,
    '& [class*="active"]': {
      color: theme.palette.text.default,
    },
    '& div[class*=row-wrapper] > div[class*=row-wrapper] > div[class*=text-field-wrapper]': {
      whiteSpace: 'nowrap',
    }
  },
  dropDownOption: {
    color: theme.palette.text.default,
    fontWeight: 'normal',
  },
}));

interface ITopFiltersGroup {
  onChange: (params: any) => void;
  onClose: () => void;
  className?: string;
}

export const TopFiltersGroup: FC<ITopFiltersGroup> = ({ className, onChange, onClose }) => {
  const classes = useStyles();
  const { dioTrack } = useDio();
  const { widgetSettings, appData, filterSettings: filterSettingsData, dioSettings } = useContext(WebsiteSettingsContext);
  const t = Translations.getInstance(appData.locale).getTranslations.bind(Translations.getInstance(appData.locale));
  const { data: filterSettings } = filterSettingsData;
  const [formProps, setFormProps] = useState<TFormProps>(formPropsInitialValues);
  const formattedAmenities = formatOptions(filterSettings?.amenities || []);
  const [amenities, setAmenities] = useState(formattedAmenities);
  // @ts-ignore
  const currencySymbol: string = (CurrencySymbols as TCurrencySymbols)[widgetSettings?.currency]?.symbol;
  const categoriesFilterName = widgetSettings?.options?.categoriesFilterName || t('Categories: All');
  const handleFormChange = useCallback((event: MultiSelectProps | SingleSelectProps) => {
    const { target: { name, value } } = event;
    setFormProps((prevProps) => {
      const newProps = {
        ...prevProps,
        [name]: value,
      };
      onChange(newProps);
      return newProps;
    });

    dioTrack({
      eventName: DIO_FILTER_EVENTS_MAP[name],
      properties: {
        ...dioSettings,
        [name]: value,
      }
    });
  }, [dioSettings, dioTrack, onChange]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const onSearchAmenities = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    const filteredAmenities = formattedAmenities.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()));
    setAmenities(filteredAmenities);
  };

  useEffect(() => {
    const amenitiesOptions = formatOptions(filterSettings?.amenities || []);
    setAmenities(amenitiesOptions);
  }, [filterSettings?.amenities]);

  if (!filterSettings?.isShowFilters) {
    return null;
  }

  return (
    <div className={cn(classes.container, className)}>
      <Row wrap fullWidth className={classes.root} spacing={2}>
        {!!filterSettings?.amenities?.length && <Dropdown
          className={classes.dropDown}
          dataKey="value"
          renderSelected="label"
          value={formProps.includeAmenities}
          variant="multiselect"
          onSearchQueryChange={onSearchAmenities}
          name="includeAmenities"
          onChange={handleFormChange}
          onClose={onClose}
          placeholder={t('Amenities: All')}
          input={<DropdownLabelInput className={classes.dropDownInputLabel} />}
        >
          {amenities?.map((option) => (
            <Option className={classes.dropDownOption} value={option} key={option.value}>
              {option.label}
            </Option>
          ))}
        </Dropdown>}

        {!!filterSettings?.propertyTypes?.length && <Dropdown
          className={classes.dropDown}
          dataKey="value"
          renderSelected="label"
          name="propertyType"
          value={formProps.propertyType}
          variant="single"
          onChange={handleFormChange}
          onClose={handleFormClose}
          placeholder={t('Property type: All')}
          input={<DropdownLabelInput className={classes.dropDownInputLabel} />}
        >
          {formatOptions(filterSettings?.propertyTypes)?.map((option) => (
            <Option className={classes.dropDownOption} value={option} key={option.value}>
              {option.label}
            </Option>
          ))}
        </Dropdown>}

        {!!filterSettings?.tags.length && <Dropdown
          className={classes.dropDown}
          name="tags"
          dataKey="id"
          renderSelected="label"
          value={formProps.tags}
          variant="multiselect"
          onChange={handleFormChange}
          onClose={onClose}
          placeholder={categoriesFilterName}
          input={<DropdownLabelInput className={classes.dropDownInputLabel} />}
        >
          {filterSettings?.tags?.map((tag) => (
            <Option className={classes.dropDownOption} value={tag} key={tag.id}>
              {tag.label}
            </Option>
          ))}
        </Dropdown>}

        {!!filterSettings.numOfBedrooms && <Dropdown
          className={classes.dropDown}
          name="numberOfBedrooms"
          value={formProps.numberOfBedrooms}
          variant="single"
          onChange={handleFormChange}
          onClose={onClose}
          placeholder={t('Bedrooms: All')}
          input={<DropdownLabelInput className={classes.dropDownInputLabel} />}
        >
          {Array.from(Array(filterSettings.numOfBedrooms).keys()).map((value) => (
            <Option className={classes.dropDownOption} value={value + 1} key={value + 1}>
              {value + 1}
            </Option>
          ))}
        </Dropdown>}

        {!!filterSettings.numOfBathrooms && <Dropdown
          className={classes.dropDown}
          name="numberOfBathrooms"
          value={formProps.numberOfBathrooms}
          variant="single"
          onChange={handleFormChange}
          onClose={onClose}
          placeholder={t('Bathrooms: All')}
          input={<DropdownLabelInput className={classes.dropDownInputLabel} />}
        >
          {Array.from(Array(filterSettings.numOfBathrooms).keys()).map((value) => (
            <Option className={classes.dropDownOption} value={value + 1} key={value + 1}>
              {value + 1}
            </Option>
          ))}
        </Dropdown>}

        {filterSettings.isShowPriceRange && (
          <DropdownMenu
            onClose={onClose}
            placeholder={t('Price per night: All')}
            className={cn(classes.priceRange, classes.dropDown)}
          >
            <div className={classes.priceInput}>
              <Input
                className={classes.priceInput}
                name="minPrice"
                value={formProps.minPrice}
                onChange={handleFormChange}
                // @ts-ignore
                type="number"
                placeholder="0"
                min={0}
                max={formProps.maxPrice}
                prefix={currencySymbol}
              />
              <div className="p-2">-</div>
              <Input
                className={classes.priceInput}
                name="maxPrice"
                value={formProps.maxPrice}
                onChange={handleFormChange}
                // @ts-ignore
                type="number"
                placeholder={t('Max')}
                min={formProps.minPrice}
                prefix={currencySymbol}
              />
            </div>
          </DropdownMenu>
        )}
      </Row>
    </div>
  );
};

export default TopFiltersGroup;
