import startCase from 'lodash/startCase';
import camelCase from 'lodash/camelCase';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';

import { IAppData, IWidgetSettings, ISearchSettings } from './types';
import { IWidgetParams } from '../api/widgetSettings/getListings';
import {
  supportedLanguages,
} from './constants';

export const getWidgetParams = (data: IAppData): IWidgetParams => ({
  websiteId: data.siteId, // websiteId
  elementId: Number(data?.elementId),
  widgetId: data?.widgetId,
  lang: data?.locale, // lang
});

export const formatOptions = (options: string[]) =>
  options?.map((value) => ({
    value,
    label: startCase(camelCase(value)),
  }));

export const formatOptionsToArray = (
  options: { value: string; label: string }[]
): string[] => options?.map((option) => option?.value);

export const removeEmptyFields = (obj: Record<string, any>): Record<string, any> => omitBy(obj, isNil);
interface IRedirectParams {
  widgetSettings: IWidgetSettings;
  locale: string;
  id?: string;
}

export const generateQueryParams = (widgetSettings: IWidgetSettings): string => {
  const { searchSettings }: { searchSettings: ISearchSettings } = widgetSettings;

  const {
    amenities,
    propertyType,
    tags,
    numOfBedrooms,
    numOfBathrooms,
    priceRange,
    location,
    // found discrepancy in the sortBy field
    // https://guesty.atlassian.net/browse/UA-7082?focusedCommentId=592793
    // sortBy,
  } = searchSettings || {};

  const queryParamsObject = removeEmptyFields({
    minPrice: priceRange?.[0],
    maxPrice: priceRange?.[1],
    currency: widgetSettings.currency,
    propertyType,
    includeAmenities: amenities,
    numberOfBedrooms: numOfBedrooms,
    numberOfBathrooms: numOfBathrooms,
    tags,
    city: location?.city,
    country: location?.country,
  });

  const searchParams = new URLSearchParams(queryParamsObject);

  return searchParams.toString();
}


function generateUrl(
  { widgetSettings, queryParams, locale, id }:
    { widgetSettings: IWidgetSettings, queryParams: string, locale: string, id: string }
) {
  const baseUrl = new URL(`https://${widgetSettings.url}/${locale}/properties`);

  if (id) {
    baseUrl.pathname = `${baseUrl.pathname}/${id}`;
    return baseUrl.toString();
  }

  if (queryParams) {
    baseUrl.search = queryParams;
  }

  return baseUrl.toString();
}

export const getRedirectUrl = ({
  widgetSettings,
  locale = 'en',
  id = '',
}: IRedirectParams) => {
  if (!widgetSettings.url) {
    return '';
  }
  const queryParams = generateQueryParams(widgetSettings);

  return generateUrl({ widgetSettings, queryParams, locale, id });
};

export const getSupportedLocales = (locale: string) => {
  if (!locale || !supportedLanguages.includes(locale)) {
    return 'en';
  }

  return locale;
};

export default {
  getWidgetParams,
  formatOptions,
  formatOptionsToArray,
  getRedirectUrl,
  getSupportedLocales,
};
